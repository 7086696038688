.main {
    width: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullPage{
    min-height: 100vh;
}

.container{
    width: 100%;
    max-width: 1000px;

    display: flex;
    flex-wrap: wrap;

    overflow: hidden;
}

.img-container {
    flex: 1 0 350px;
    padding: 3rem;
    max-height: 60vh;
}

.img-container>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.text-container{
    flex: 1 0 350px;
    padding: 3rem;

    display: flex;
    flex-direction: column;

    justify-content: center;
}

.text-container>*{
    margin: 1rem 0!important;
}

.text-container>p{
    text-align: justify;
}

.text-container>button{
    align-self: center;
    padding: 1rem;
}