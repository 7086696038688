* {
    margin: 0;
    box-sizing: border-box;
}

:root {
    font-family: "Roboto", sans-serif;
    font-weight: lighter;

    background-color: #fafafa;

    --material-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

h1,h2,h3,h4,h5,h6{
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

@media print { 
    /* All your print styles go here */
    #header, #footer, #nav { display: none !important; } 

    *{
        box-sizing: border-box;
    }

    @page {
        margin: 16px;
    }
}