.container {
    width: 100%;
    min-height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.main{
    width: 100%;
    max-width: 1000px;

    display: flex;
    flex-wrap: wrap;

    background-color: white;

    border-radius: 0.5rem;
    overflow: hidden;
}

.header{
    flex: 1 0 325px;
    padding: 3rem;

    color: white;

    display: flex;
    flex-direction: column;

    justify-content: center;
}

.header> h1{
    margin: 2rem 0;

    font-size: 2rem;
    text-align: center;
}

.header>h2{
    text-align: center;
    font-size: 1.5rem;
    font-weight: lighter;
}

.header>.logo{
    height: 200px;
    width: 100%;
}

.logo>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.form-div{
    flex: 2 0 325px;
    padding: 3rem;

    display: flex;
    flex-direction: column;

    justify-content: center;
}

.form-div>h2{
    margin: 1.5rem 0;
}

.login-form{
    display: flex;
    flex-direction: column;
}

div.input{
    margin: 0.5rem 0;
}

button.button{
    padding: 1rem;
    margin: 0.5rem 0;
    text-align: center;
}

a.redirectBtn{
    padding: 1rem;
    margin: 0.5rem 0;
    text-align: center;
}

.google{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 8px!important;
    text-transform: uppercase;
}