.form{
    width: 100%;
    padding: 1rem;

    display: flex;
    flex-direction: column;
}

.form>*{
    margin: 0.5rem 0!important;
}

.form>button{
    padding: 1rem;
}