.container {
    width: 100%;
    align-self: stretch;

    padding: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container>h1{
    text-align: center;

    margin: 2rem 0;
}

.tab-container {
    width: 100%;
    max-width: 750px;

    overflow: hidden;
}